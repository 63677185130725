<script>
import { fetchPrivacyNotice } from '@/api'

export default {
  metaInfo() {
    return {
      title: this.type,
      titleTemplate: '%s - Privacy notice - Europe PMC',
      meta: [{ name: 'description', content: this.type }],
    }
  },
  props: {
    type: {
      type: String,
      default: 'website',
    },
  },
  data() {
    return {
      privacyNotice: null,
    }
  },
  computed: {
    key() {
      let key = ''
      const { type } = this
      if (type === 'advanced-user-services') {
        key = '5d182831-5e99-4072-816b-380cda5f58df'
      } else if (type === 'ux-research') {
        key = '0851ad52-89e5-406e-8f6a-e374feccae1e'
      } else {
        key = 'd667efee-2af8-401f-b7e8-dcc6db7e9a0f'
      }
      return key
    },
    content() {
      const { privacyNotice } = this

      let content = privacyNotice ? privacyNotice.body : ''
      if (
        content.includes('h2') &&
        !content.includes('h3') &&
        content.includes('h4')
      ) {
        content = content.replace(/h4/g, 'h3')
      }

      const temp = document.createElement('div')
      temp.innerHTML = content
      const invalidElements = temp.querySelectorAll('ul .field-items')
      if (invalidElements.length) {
        for (let i = 0; i < invalidElements.length; i++) {
          const ul = invalidElements[i].closest('ul')
          ul.outerHTML = ul.innerHTML
        }
      }

      return temp.innerHTML
    },
  },
  async created() {
    const response = await fetchPrivacyNotice(this.key)
    this.privacyNotice = response ? response.nodes[0].node : null
  },
}
</script>
<template>
  <div id="privacy-notice">
    <div class="grid-row">
      <div class="col-11">
        <template v-if="privacyNotice">
          <h1>{{ privacyNotice.title }}</h1>
          <div>
            Last changed: {{ privacyNotice.changed }}; Version:
            {{ privacyNotice['version Count'] }}
            <!--removed version count from UI since we use different endpoint to fetch privacy notice which has higher versions went under multiple reviews-->
          </div>
          <div v-if="content" v-html="content" />
        </template>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#privacy-notice {
  padding-top: $base-unit * 6.5;
  padding-bottom: $base-unit * 13;
}
</style>
