import { render, staticRenderFns } from "./PrivacyNotice.vue?vue&type=template&id=69569486&scoped=true"
import script from "./PrivacyNotice.vue?vue&type=script&lang=js"
export * from "./PrivacyNotice.vue?vue&type=script&lang=js"
import style0 from "./PrivacyNotice.vue?vue&type=style&index=0&id=69569486&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69569486",
  null
  
)

export default component.exports